* {
    background-color: aliceblue;
}

.content {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.email {
    margin: 10px;
    box-shadow: inset #abacaf 0 0 0 2px;
    border: 0;
    background: rgba(0, 0, 0, 0);
    appearance: none;
    width: 83%;
    position: relative;
    border-radius: 3px;
    padding: 9px 12px;
    line-height: 1.4;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    transition: all .2s ease;
}
.email:hover{
    box-shadow: 0 0 0 0 #fff inset,#585858 0 0 0 2px;
}
.email:focus{
    background: #fff;
    outline: 0;
    box-shadow: 0 0 0 0 #fff inset,#585858 0 0 0 3px;
}


.submit {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
    padding: 12px 24px;
    border: 0;
    color: #fff;
    background: #000a47;
    line-height: 1.15;
    font-size: 16px;
}
.submit:hover {
    transition: all .1s ease;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px#585858;
}

.surroundsubmit {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}